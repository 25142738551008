<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form v-bind="searchLayout">
          <a-row>
            <a-col :sm="6" :xxl="4">
              <s-input
                title="商品名称"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.GoodsTitle"
              />
            </a-col>

            <a-col :sm="6" :xxl="4">
              <s-tree-select
                :labelCol="8"
                :valueCol="14"
                title="商品分类"
                :fieldNames="replaceFields"
                v-model:value="queryParams.GoodsCategoryId"
                :options="treeData"
              />
            </a-col>

            <a-col :sm="8" :lg="6">
              <a-button type="primary" @click="getDataList(1)">查询</a-button>
              <a-button
                type="primary"
                style="margin-left: 15px"
                @click="exportExcel"
                >
                导出Excel
                </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'count'">
              <a-tooltip>
                <template #title>修改数量</template>
                <a @click.stop="showChangeCount(record)">
                  <a-tag color="blue">{{ record.count }}</a-tag>
                </a>
              </a-tooltip>
            </template>

            <template v-if="column.key === 'totalPrice'">
              <span v-if="record.totalPrice > 0">{{ record.totalPrice }}</span>
              <span v-else>暂无报价</span>
            </template>

            <template v-if="column.key === 'actions'">
              <a-tooltip>
                <template #title>删除</template>
                <a @click.stop="delForm(() => delFormFun(record))">
                  <DeleteOutlined />
                </a>
              </a-tooltip>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>

    <EChangeNum
      ref="eChangeNum"
      title="修改数量"
      label="数量"
      @ok="onChangeCount"
    />
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import { PlanItemClass } from '@/apis/plan'
import page from '@/mixins/page'
import {
  ExclamationCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'
import EChangeNum from '@/components/forms/ChangeNum'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'

const api = new PlanItemClass()
export default defineComponent({
  components: {
    DeleteOutlined,
    EChangeNum
  },
  mixins: [page],
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '商品分类',
        key: 'goodsCategoryTitle',
        dataIndex: 'goodsCategoryTitle',
        width: 150
      },
      {
        title: '商品',
        key: 'goodsTitle',
        dataIndex: 'goodsTitle',
        width: 200
      },
      {
        title: '库存',
        key: 'quantity',
        dataIndex: 'quantity',
        width: 100
      },
      {
        title: '订购数量',
        key: 'count',
        dataIndex: 'count',
        width: 100
      },
      {
        title: '单位',
        key: 'unit',
        dataIndex: 'unit',
        width: 90
      },
      {
        title: '规格',
        key: 'specification',
        dataIndex: 'specification',
        width: 150
      },
      {
        title: '采购单价',
        key: 'unitPrice',
        dataIndex: 'unitPrice',
        width: 100
      },
      {
        title: '总价',
        key: 'totalPrice',
        dataIndex: 'totalPrice',
        width: 120
      },
      {
        title: '备注',
        key: 'remark',
        dataIndex: 'remark'
      },
      {
        title: '操作',
        key: 'actions',
        dataIndex: 'actions',
        width: 120
      }
    ]

    const treeData = ref([])
    return {
      columns,
      loading,
      height,
      width,
      treeData,
      replaceFields: {
        value: 'id',
        key: 'id'
      }
    }
  },
  created () {
    this.queryParams.PlanId = this.$route.params.planId
    this.getDataList(1)
  },
  methods: {
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit

      q.UserId = this.$store.state.user.userInfo.id
      api.getItems(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },

    updatePrice (record) {
      api.setPrice(record.planId, record.goodsId, record.unitPrice).then(() => {
        this.$message.success('修改单价成功！')
        this.getDataList(this.page.page)
      })
    },

    editCount (record) {
      this.id = record.id
      this.$refs.eChangeNum.open(record.count)
    },

    okCount (e) {
      api
        .edit({
          id: this.id,
          count: e
        })
        .then(() => {
          this.$refs.eChangeNum.handleClose()
          this.getDataList(this.page.page)
        })
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    },

    delFormFun (record) {
      const _this = this
      _this.$confirm({
        title: '确定删除?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          `即将删除采购商品：${record.goodsTitle}`
        ),

        onOk () {
          api.delGoods(record.goodsId).then(() => {
            _this.$message.success('删除成功！')
            _this.getDataList(1)
          })
        }
      })
    },

    onChangeCount (e) {
      api
        .edit({
          id: this.planItem.id,
          count: e
        })
        .then(() => {
          this.$message.success('修改成功')
          this.$refs.eChangeNum.handleClose()
          this.getDataList(this.page.page)
        })
        .catch(() => {
          this.$refs.eChangeNum.handleClose()
        })
    },
    showChangeCount (record) {
      this.planItem = record
      this.$refs.eChangeNum.open(record.count)
    },

    exportExcel () {
      api
        .exportPlanItems({
          UserId: this.$store.state.user.userInfo.id,
          PlanId: this.queryParams.PlanId
        })
        .then((resp) => {
          fileDownload(
            resp,
            '采购清单' + dayjs().format('YYYYMMDDHHmmss') + '.xlsx'
          )
        })
    }
  }
})
</script>

<style lang="less" scoped>
.goods-info {
  display: flex;
  .goods-image {
    width: 100px;
    height: 100px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .goods-title {
    padding-left: 10px;
    h1 {
      font-size: 14px;
      padding: 0;
      margin: 0;
      font-weight: 600;
      line-height: 30px;
    }
    p {
      font-size: 12px;
      color: #9b9b9b;
      line-height: 20px;
      height: 40px;
      padding: 0;
      margin: 0;
    }
    .goods-tags {
      font-size: 12px;
      line-height: 30px;
      height: 30px;
    }
  }
}
</style>
